import classNames from "classnames"
import { bool, func, object, oneOfType, string } from "prop-types"

import ModalHandler from "../../providers/Modal/ModalHandler"
import ButtonCta from "../Buttons/button/button-cta"
import Preloader from "../loaders/preloader/preloader"
import Modal from "../Modal/Modal"

import "./ConfirmPopin.scss"

export const ConfirmPopin = ModalHandler.create(({
    title,
    description,
    buttonsLabel,
    actions,
    customClass,
    visible,
    hide,
    isLoading,
    error,
    isAlert,
}) => {
    return (
        <Modal
            isOpen={visible}
            className={classNames("confirmPopin", {
                [customClass]: customClass,
            })}
            onClose={hide}
        >
            <header>{title ?? "Confirmation"}</header>
            <section className="Section">
                {description && <div className="description">{description}</div>}
                {!isLoading ? (
                    <>
                        {error ? <div className="error">{error}</div> : (
                            <div className="buttonsWrapper">
                                <ButtonCta
                                    text={buttonsLabel?.confirm ?? "Oui"}
                                    onClick={e => {
                                        actions?.confirm(e)
                                        if (isAlert) {
                                            hide()
                                        }
                                    }}
                                />
                                {!isAlert && (
                                    <ButtonCta
                                        text={buttonsLabel?.decline ?? "Non"}
                                        onClick={
                                            (e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                actions?.decline?.()
                                                hide()
                                            }
                                        }
                                        isCta2Alt
                                    />
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <Preloader small/>
                )}
            </section>
        </Modal>
    )
})

ConfirmPopin.displayName = "ConfirmPopin"

ConfirmPopin.propTypes = {
    actions: object,
    buttonsLabel: oneOfType([ bool, object ]),
    customClass: oneOfType([ bool, string ]),
    description: oneOfType([ bool, string ]),
    error: oneOfType([ bool, string ]),
    isAlert: bool,
    isLoading: bool,
    isOpen: bool,
    setIsOpen: func,
    title: string,
}

export default ConfirmPopin 
