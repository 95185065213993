import { useContext } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { LocationPaths } from "../../../../../routes/ApplicationPaths/locationPaths.js"
import { updateGameSession } from "../api.js"
import { ArbitrageContext } from "../ArbitrageContext.js"

export default function ArbitrageExit() {
    const { gameSession } = useContext(ArbitrageContext)

    const navigate = useNavigate()
    const params = useParams()

    const confirm = () => {
        updateGameSession(gameSession.GameSessionId, {
            ...gameSession,
            End: new Date(),
            IsEnded: true,
        })

        navigate(LocationPaths.toReservationInfos(params.locationId))
    }

    return (
        <div className={"arbitrage-exit"}>
            <div className={"arbitrage-exit-head"}>
                <Link

                    to={"../recapitulatif"}
                    className={"arbitrage-exit-head-cancel"}
                >
                    <img src="/assets/images/refeering/exit.svg"  alt={"exit"} />
                </Link>
            </div>

            <div className={"arbitrage-exit-content"}>
                <div className={"arbitrage-exit-icon"}>
                    <img src="/assets/images/refeering/arbitrage-icon.svg" alt={"icon arbitrage"} />
                </div>

                <div className={"arbitrage-exit-title"}>
                    <div className={"arbitrage-exit-title-first"}>
                        Es-tu sûr de vouloir
                    </div>

                    <div className={"arbitrage-exit-title-second"}>
                        — quitter l'arbitrage
                        du match ?
                    </div>
                </div>

                <div className={"arbitrage-exit-actions"}>
                    <button
                        onClick={confirm}
                        className={"buttonCta isAlt"}
                    >
                        OUI
                    </button>

                    <Link
                        to={"../recapitulatif"}
                        className={"buttonCta"}
                    >
                        NON
                    </Link>
                </div>
            </div>
        </div>
    )
}
