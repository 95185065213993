import { array, bool, func, object, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"

import useMediaPath from "../../../hooks/useMediaPath"
import { useLocationStore } from "../../../pages/reservation/store/store.js"
import ModalHandler from "../../../providers/Modal/ModalHandler"
import ButtonCta from "../../Buttons/button/button-cta"
import Player from "../../reservation/Player/Player"
import ShareLink from "../../ShareLink/ShareLink"
import { useTheme } from "../../ThemeContext/ThemeContext"
import RenameTeamsPopin from "../RenameTeamsPopin/RenameTeamsPopin"

import "./CompositionAside.scss"

export default function CompositionAside({
    players,
    playersBenchList,
    autoComposition,
    nodeToImage,
    handlePlayersFilling,
    selectedPlayer,
    emptyCaseSelection,
    team1Name,
    setTeam1Name,
    team2Name,
    setTeam2Name,
    ajaxLoading,
    openInvitationPopin,
    removePlayerFromField,
}) {
    const { currentLocation } = useLocationStore()
    const theme = useTheme()
    const mediaPath = useMediaPath()

    const [ linkToShare, setLinkToShare ] = useState("")
    const [ publicDetailsGenerated, setPublicDetailsGenerated ] = useState(false)

    const isOwner = currentLocation.isOwner

    const renameTeams = () => {
        ModalHandler.show(RenameTeamsPopin, {
            setTeam1Name,
            setTeam2Name,
            team1Name,
            team2Name,
        })
    }

    const generateShareLink = (location) => {
        let shareLink = window.location.origin
            + "/" + (theme === "theme-padel" ? "padel" : "")
            + "?action=tokenActivation&invitation=TYPE_LOCATION&theme="
            + theme
            + "&id="
            + location.id
            + "&PJT="
            + encodeURIComponent(location.publicJoinToken)
            + "&fromUser="
            + (location.organizer.firstname + " " + location.organizer.lastname)

        return encodeURI(shareLink)
    }

    useEffect(() => {
        if (currentLocation?.publicJoinToken) {
            setLinkToShare(generateShareLink(currentLocation, theme, publicDetailsGenerated, setPublicDetailsGenerated))
        } else {
            setLinkToShare("")
        }

    }, [ currentLocation ])

    return (
        <div className="composition-aside">
            {isOwner && !ajaxLoading && (
                <>
                    <div className="player-list">
                        {
                            players.length > 0 && (
                                <div className="button-wrapper">
                                    <ButtonCta
                                        icon={mediaPath([ "/assets/icons/random-compo.svg", "/assets/images/padel/icons/random-compo-red.svg" ])}
                                        isAlt
                                        text="compo auto"
                                        onClick={autoComposition}
                                        hoverChangeIcon={mediaPath([ "/assets/icons/random-compo-white.svg", "/assets/images/padel/icons/random-compo-green.svg" ])}
                                        className="compo-button"
                                    />
                                </div>
                            )
                        }

                        {playersBenchList.length > 0 ?
                            playersBenchList.map(
                                player => (
                                    <Player
                                        onBench={true}
                                        player={player}
                                        key={player.id + "-" + player.firstname}
                                        onClick={handlePlayersFilling}
                                        closeOnClick={() => removePlayerFromField(player)}
                                        fieldPlace={[]}
                                        selected={selectedPlayer?.id === player.id}
                                        isMVP={currentLocation.mvp && player.id === currentLocation.mvp.id}
                                    />
                                ),
                            )
                            : (
                                playersBenchList.length === 0 && (
                                    <Player
                                        onBench={true}
                                        onClick={handlePlayersFilling}
                                        fieldPlace={[]}
                                        selected={Array.isArray(emptyCaseSelection) && emptyCaseSelection.length === 0}
                                    />
                                )
                            )}
                        {currentLocation?.maxAttendees !== currentLocation?.currentAttendees && (
                            <img
                                className="inviteFriends"
                                src={mediaPath([ "/assets/icons/inviteIcon.svg", "/assets/images/padel/icons/inviteIcon.svg" ])}
                                onClick={openInvitationPopin}
                            />)}
                    </div>
                    <div className="button-wrapper">
                        <ShareLink icon="" label="partager le lien du match" shareLink={linkToShare} />
                        <ButtonCta
                            isAlt
                            className="saveImage"
                            text="Partager la feuille de match"
                            onClick={nodeToImage}
                        />
                        <ButtonCta
                            className="rename"
                            text="Renommer les équipes"
                            onClick={renameTeams}
                            isCta2Alt
                        />
                    </div>
                </>
            )}
            {!isOwner && !ajaxLoading && (
                <>
                    <ShareLink icon="" label="partager le lien du match" shareLink={linkToShare} />
                    <ButtonCta
                        icon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus.svg" ])}
                        text="Inviter mes joueurs"
                        onClick={openInvitationPopin}
                        hoverChangeIcon={mediaPath([ "/assets/images/picto-add-user--white.svg", "/assets/images/padel/icons/icons-player-plus-green.svg" ])}
                    />
                    <ButtonCta
                        isCta2
                        disabled={!players.some(player => player.teamId)}
                        text="Partager la feuille de match"
                        onClick={() => {
                            nodeToImage()
                        }}
                    />
                    {!players.some(player => player.teamId) &&
                        <p>L'organisateur n'a pas fait la composition de ton équipe.</p>
                    }
                </>
            )}
        </div>
    )
}

CompositionAside.propTypes = {
    ajaxLoading: bool,
    autoComposition: func,
    emptyCaseSelection: oneOfType([ bool, array ]),
    handlePlayersFilling: func,
    nodeToImage: func,
    openInvitationPopin: func,
    players: array,
    playersBenchList: array,
    removePlayerFromField: func,
    selectedPlayer: oneOfType([ bool, object ]),
    setTeam1Name: func,
    setTeam2Name: func,
    team1Name: string,
    team2Name: string,
}
