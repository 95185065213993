import { useEffect, useState } from "react"

export function usePreventLockScreen() {
    const [ wakeLock, setWakeLock ] = useState(null)

    useEffect(() => {
        if ("wakeLock" in navigator) {
            const acquire = () => navigator.wakeLock.request("screen")
                .then(wakeLock => setWakeLock(wakeLock))
                .catch()

            acquire()

            document.addEventListener("visibilitychange", async () => {
                if (wakeLock !== null && document.visibilityState === "visible") {
                    acquire()
                }
            })

            return () => wakeLock?.release()
        }
    }, [])
}
