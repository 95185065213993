import dayjs from "dayjs"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"

import { getLeagueDetailed } from "./api/api.js"
import { VisualCard } from "./components/VisualCard.jsx"
import { VisualsFilters } from "./components/VisualsFilters.jsx"
import { communicationPackageConfig } from "./config.js"
import Preloader from "../../components/loaders/preloader/preloader.jsx"
import { getLeagueGames, getLeagueTeamPlayers } from "../league/api/myLeague.js"
import { useLeagueStore } from "../league/store/useLeagueStore.js"

import "./CommunicationPackage.scss"

export const CommunicationPackage = () => {
    const { selectedLeagueTeam } = useLeagueStore()
    const [ games, setGames ] = useState([])
    const [ players, setPlayers ] = useState([])
    const [ leagueDetailed, setLeagueDetailed ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ currentFilter, setCurrentFilter ] = useState("team")

    useEffect(() => {
        if (selectedLeagueTeam?.id) {
            setLoading(true)

            const leagueGamesPromise = getLeagueGames(selectedLeagueTeam.leagueId, selectedLeagueTeam.id)
            const leagueDetailedPromise = getLeagueDetailed(selectedLeagueTeam.leagueId)
            const teamPlayers = getLeagueTeamPlayers(selectedLeagueTeam.id)

            Promise.all([ leagueGamesPromise, leagueDetailedPromise, teamPlayers ])
                .then(([ gamesData, leagueDetailedData, teamPlayersData ]) => {
                    setPlayers(teamPlayersData)

                    const teamGames = Object.values(gamesData?.games).reduce(
                        (acc, val) => acc.concat(val), [],
                    ).filter(
                        (game) => game.localTeam?.teamId === selectedLeagueTeam.id
                            || game.visitorTeam?.teamId === selectedLeagueTeam.id,
                    )
                    setGames(teamGames.map((game) => {
                        const gameName =
                            "VS " + game.visitorTeam?.team + " - " + dayjs(game.date).format("DD/MM/YYYY HH:mm")
                        return {
                            data: game,
                            label: gameName,
                            value: game.id,
                        }
                    }))

                    setLeagueDetailed(leagueDetailedData)

                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }, [ selectedLeagueTeam?.id ])

    return (
        <div className={"cp-container theme-business-print"}>
            {loading && <Preloader fixed/>}
            <h1>Pack de communication</h1>
            <VisualsFilters onFilterChange={setCurrentFilter} value={currentFilter}/>
            <motion.div className={"cp-list"}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                {communicationPackageConfig.filter((config) =>
                    config.category === currentFilter).map((config, index) => {
                    return (
                        <VisualCard
                            data={{
                                games: config?.contentFilter ? config?.contentFilter(games) : games,
                                league: leagueDetailed,
                                players,
                            }}
                            config={config}
                            key={index}
                        />
                    )
                },
                )}
            </motion.div>
        </div>
    )
}

const containerVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: {
        height: "auto",
        opacity: 1,
        transition: {
            staggerChildren: 0.07,
        },
    },
}
