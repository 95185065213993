import { useEffect, useState } from "react"

export function Clock() {
    const [ time, setTime ] = useState((new Date).toLocaleTimeString())

    useEffect(() => {
        const interval = setInterval(() => setTime((new Date).toLocaleTimeString()))

        return () => clearInterval(interval)
    }, [])

    return (
        <span className={"clock"}>
            <img src="/assets/icons/heure-orange.svg" alt="hour" className={"clock-icon"}/>

            <span>{time.slice(0, 2)}</span>
            :
            <span>{time.slice(3, 5)}</span>
            :
            <span className={"clock-seconds"}>{time.slice(-2)}</span>
        </span>
    )
}
