import { useContext } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { gameSessionAddGoal } from "../api.js"
import { ArbitrageContext } from "../ArbitrageContext.js"

export default function ArbitrageGoal() {
    const { gameSession, setGameSession } = useContext(ArbitrageContext)
    const params = useParams()
    const navigate = useNavigate()

    const add = contact => {
        gameSessionAddGoal(gameSession.GameSessionId, {
            ContactId: contact,
            Team: parseInt(params.team),
        }).then(result => {
            setGameSession(result.data.data)
        }).catch()

        navigate("..")
    }

    if (![ "1", "2" ].includes(params.team)) {
        navigate("..")
    }

    return  (
        <div className={"arbitrage-goal"}>
            <div className={"arbitrage-goal-title"}>
                <div>{gameSession[`Team${params.team}Name`]}</div>

                <Link
                    to={".."}
                    className={"arbitrage-goal-back"}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            d="M12.4814 9.99974L19.4859 2.99539C20.1714 2.30996 20.1714 1.19951 19.4859 0.514076C18.8005
                                -0.171359 17.69 -0.171359 17.0045 0.514076L10 7.51843L2.99547 0.514076C2.31002
                                -0.171359 1.19954 -0.171359 0.514089 0.514076C-0.171363 1.19951 -0.171363 2.30996
                                0.514089 2.99539L7.51862 9.99974L0.514089 17.0041C-0.171363 17.6895 -0.171363
                                18.8 0.514089 19.4854C0.855774 19.8271 1.3058 20 1.75374 20C2.20168 20 2.6517
                                19.8292 2.99339 19.4854L10 12.4811L17.0045 19.4854C17.3462 19.8271 17.7962 20 18.2442
                                20C18.6921 20 19.1421 19.8292 19.4838 19.4854C20.1693 18.8 20.1693 17.6895 19.4838
                                17.0041L12.4814 9.99974Z"
                        />
                    </svg>
                </Link>
            </div>

            <div className={"arbitrage-goal-grid"}>
                {gameSession[`PlayersTeam${params.team}`]?.map(player => (
                    <button
                        key={player.ContactId}
                        className={"arbitrage-goal-player"}
                        onClick={() => add(player.ContactId)}
                    >
                        <img
                            src={player.detail?.pictureUrl ?? "/assets/images/user-placeholder-b.svg"}
                            alt="profile"
                            className={"arbitrage-goal-player-img"}
                        />

                        <div className={"arbitrage-goal-player-name"}>
                            {player.Name}
                        </div>
                    </button>
                ))}

                <button
                    className={"arbitrage-goal-player"}
                    onClick={() => add(null)}
                >
                    <img
                        src={"/assets/images/user-placeholder-b.svg"}
                        alt="profile"
                        className={"arbitrage-goal-player-img"}
                    />

                    <div className={"arbitrage-goal-player-name"}>
                        Invité
                    </div>
                </button>
            </div>
        </div>
    )
}
