import { bool, func } from "prop-types"

import ButtonCta from "../../../../../components/Buttons/button/button-cta.jsx"
import useMediaPath from "../../../../../hooks/useMediaPath.js"

export function ArbitrageButton({ onClick, disabled }) {
    const mediaPath = useMediaPath()

    return (
        <ButtonCta
            text="Arbitre ton match"
            onClick={onClick}
            hoverChangeIcon={mediaPath([
                "/assets/images/picto-add-user--white.svg",
                "/assets/images/padel/icons/icons-player-plus-green.svg",
            ])}
            className={"arbitrage-access-button"}
            disabled={disabled}
        />
    )
}

ArbitrageButton.propTypes = {
    disabled: bool,
    onClick: func,
}
