import classNames from "classnames"
import dayjs from "dayjs"
import weekday from "dayjs/plugin/weekday"
import { object } from "prop-types"
import { useEffect, useRef, useState } from "react"
import { IMaskInput } from "react-imask"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getCentersMessages } from "./api"
import BillSubscriptionCancel from "./components/BillSubscriptionCancel"
import CancelPopin from "./components/CancelPopin"
import NormalCancel from "./components/NormalCancel"
import RegisterSubscriptionCancel from "./components/RegisterSubscriptionCancel"
import ReportPopin from "./components/ReportPopin"
import {
    generateProgressItems,
    getCurrentReservationCancellationRule,
    matchResultDisplay,
    reMatch,
    sendScores,
} from "./functions"
import ButtonCta from "../../../../components/Buttons/button/button-cta"
import CustomProgressBar from "../../../../components/CustomProgressBar/CustomProgressBar"
import Preloader from "../../../../components/loaders/preloader/preloader"
import LocationInfoBar from "../../../../components/LocationInfoBar/LocationInfoBar"
import { useTheme } from "../../../../components/ThemeContext/ThemeContext"
import { getAbonnement } from "../../../../globalAPI/api"
import useLocationVideos from "../../../../hooks/reservation/location/useLocationVideos"
import { getInCache, saveInCache } from "../../../../hooks/reservation/useCache"
import useGoToCreateSuperSub from "../../../../hooks/supersub/useGoToCreateSuperSub"
import useMediaPath from "../../../../hooks/useMediaPath"
import useResetScroll from "../../../../hooks/useResetScroll"
import useThemeFeatures from "../../../../hooks/useThemeFeatures"
import useThemeHeader from "../../../../hooks/useThemeHeader"
import ModalHandler from "../../../../providers/Modal/ModalHandler.jsx"
import { LocationPaths } from "../../../../routes/ApplicationPaths/locationPaths.js"
import usePath from "../../../../routes/services/usePath"
import { useLocationStore } from "../../store/store.js"
import CantBook from "../../tunnelReservation/NewBookingTunnel/CantBook/CantBook"
import PadelScore from "../components/PadelScore"
import { getPlayersComposition } from "../LocationCompositionManagement/api"

import "./infoTab.scss"

dayjs.extend(weekday)

function InfoTab() {
    const { currentLocation: location, setCurrentLocation } = useLocationStore()
    const userLoaded = useSelector(state => state.userLoaded)
    const hasArdoises = useSelector(state => state.hasArdoises)
    const mediaPath = useMediaPath()
    const navigate = useNavigate()
    const path = usePath()

    const [ isLoading, setIsLoading ] = useState(false)
    const [ score1, setScore1 ] = useState("")
    const [ score2, setScore2 ] = useState("")
    const [ rentalInfo, setRentalInfo ] = useState(null)
    const [ padelScore1, setPadelScore1 ] = useState([ "", "" ])
    const [ padelScore2, setPadelScore2 ] = useState([ "", "" ])
    const [ longerTeamName, setLongerTeamName ] = useState({ team: null, width: null })
    const [ cancellationRule, setCancellationRule ] = useState(null)
    const theme = useTheme()
    const themeFeatures = useThemeFeatures()
    const themeHeader = useThemeHeader()
    const [ progressBarItems, setProgressBarItems ] = useState([])

    const [ subscription, setSubscription ] = useState(null)

    useResetScroll()

    const { hasVideos } = useLocationVideos(location)

    const goToCreateSuperSub = location ? useGoToCreateSuperSub(location, location.amountDue, 0) : () => {}

    const cancelPopin = useRef(null)
    const score1Ref = useRef(null)
    const score2Ref = useRef(null)

    const isOrganizer = userLoaded?.contactId === location?.organizer?.id

    const whoIsWinner = location?.scoreTeam1 !== null && location?.scoreTeam2 !== null ? location?.scoreTeam1 > location?.scoreTeam2 : null
    const isDrawGame = location?.scoreTeam1 !== null && location?.scoreTeam2 !== null && location?.scoreTeam1 === location?.scoreTeam2
    let freeCancelDate

    if (location?.start) {
        const twoDayBefore = new Date(new Date(location.start).getTime() - (48 * 60 * 60 * 1000))
        freeCancelDate = new Date() > twoDayBefore ?  null : dayjs(twoDayBefore).format("DD MMMM YYYY à HH[h]mm")
    }

    const isMatchNotPlayed = location && (new Date() < new Date(location.end))
        && !(location.scoreTeam2 !== null && location.scoreTeam1 !== null)

    const disabledScore = !isOrganizer || isMatchNotPlayed ||
        location.scoreTeam1 !== null || location.scoreTeam2 !== null
    function cancelReservation() {
        cancelPopin.current.show()
    }

    const openReport = () => {
        ModalHandler.show(ReportPopin, {
            location: location,
        })
    }

    useEffect(
        () => {
            if (location && Object.keys(location).length) {
                const locationCenterId = location.centerId
                const cacheId = "my-urban-rental-information-" + locationCenterId
                const infoFromCache = getInCache(cacheId, theme)

                if (infoFromCache) {
                    setRentalInfo(infoFromCache)
                } else {
                    getCentersMessages({ activity: themeHeader }).then(
                        res => {
                            const centerMessage = res.find(
                                r => parseInt(r.key) === locationCenterId,
                            )
                            if (centerMessage?.value) {
                                saveInCache({
                                    expire: 30,
                                    key: cacheId,
                                    overRide: true,
                                    theme: theme,
                                    value: centerMessage.value,
                                })
                                setRentalInfo(centerMessage.value)
                            }
                        },
                    )
                }
            }
            if (location?.subscriptionId) {
                getAbonnement(location.subscriptionId).then((res) => {
                    setSubscription(res)
                })
            }
        }, [ location ],
    )

    useEffect(
        () => {
            setIsLoading(true)

            if (
                location
                && !location.Message
                && Object.keys(location).length > 0
                && location.scoreTeam1 !== null
                && location.scoreTeam2 !== null
            ) {
                setScore1(location.scoreTeam1.toString())
                setScore2(location.scoreTeam2.toString())
                if (location.scorePadelDetailled) {
                    setPadelScore1(location.scorePadelDetailled.map(score => score.scoreTeam1.toString()))
                    setPadelScore2(location.scorePadelDetailled.map(score => score.scoreTeam2.toString()))
                } else {
                    setPadelScore1([ location.scoreTeam1.toString() ])
                    setPadelScore2([ location.scoreTeam2.toString() ])
                }
            }

            setProgressBarItems(generateProgressItems(location))

            if (location?.id && !location.composition) {
                getPlayersComposition(location.id, { activity: themeHeader }).then(
                    (res) => {
                        if (res?.location?.id) {
                            const presentsInvites = res?.location?.invites?.filter(player => player.status === 1)
                            const filteredAttendees = presentsInvites?.map(player => ({
                                ...player,
                                teamId: res?.attendees?.find(attendee => player.id === attendee.id)?.teamId ,
                                teamSlot: res?.attendees?.find(attendee => player.id === attendee.id)?.teamSlot,
                            }))
                            setCurrentLocation({
                                ...res.location,
                                composition: filteredAttendees,
                                goal: res.goals ?? null,
                            })
                        }
                        setIsLoading(false)
                    },
                )
            } else {
                setIsLoading(false)
            }
        },[ location ],
    )
    
    useEffect(() => {
        if (location?.team1Name && location?.team2Name && score1Ref?.current && score2Ref?.current) {
            if (location.team1Name.length > location.team2Name.length) {return setLongerTeamName({ team: 1, width: score1Ref.current.offsetWidth + "px" })}
            if (location.team1Name.length < location.team2Name.length) {return setLongerTeamName({ team: 2, width: score2Ref.current.offsetWidth + "px" })}
            if (location.team1Name.length === location.team2Name.length) {return setLongerTeamName({ team: null, width: null })}
        }
    }, [ location, score1Ref, score1Ref ])

    useEffect(() => {
        if (location) {
            setCancellationRule(getCurrentReservationCancellationRule(location))
        }
    }, [ location ])

    return (
        <>
            {location ? (
                <div className="infoTab flex-column align-center">
                    {isLoading && <Preloader fixed={true} />}
                    {(!isOrganizer && location.scoreTeam1 === null && location.scoreTeam2 === null && !isMatchNotPlayed) ?
                        (
                            <div className="waitingOrganizerScores">
                                <img src={mediaPath([ "/assets/icons/hourglass-in-circle.svg", "/assets/images/padel/icons/icon-hourglass-red-line.svg" ])}/>
                                <em>On attend le score de l'organisateur</em>
                            </div>
                        )
                        : !isMatchNotPlayed && (
                            <div className="locationScore">
                                <div className="title">Résultat</div>
                                {!isMatchNotPlayed && location.scoreTeam1 !== null && location.scoreTeam2 !== null && matchResultDisplay(location, userLoaded, location, theme)}
                                <div className="teamScores">
                                    <div className={classNames("teamScore", {
                                        winnerTeam: whoIsWinner && !isDrawGame && location.scoreTeam1 !== null && location.scoreTeam2 !== null,
                                    })}>
                                        <div
                                            className={classNames("teamName", {
                                                winner: whoIsWinner && !isDrawGame && location.scoreTeam1 !== null && location.scoreTeam2 !== null,
                                            })}
                                        >
                                            <span
                                                style={longerTeamName?.team === 2 ? { width: longerTeamName.width } : undefined}
                                                ref={score1Ref}
                                            >
                                                {location.team1Name}
                                            </span>
                                        </div>
                                        <div className="inputsWrapper">
                                            {theme === "theme-soccer" && (<IMaskInput
                                                className={disabledScore ? "" : "dottedBorder"}
                                                disabled={disabledScore}
                                                mask={Number}
                                                max={99}
                                                scale={0}
                                                value={score1}
                                                onChange={
                                                    (e) => {
                                                        setScore1(e.target.value)
                                                    }
                                                }
                                                normalizeZeros={false}
                                                type="number"
                                                pattern="\\d*"
                                            />)}
                                            { theme === "theme-padel" && (
                                                <PadelScore
                                                    padelScore={padelScore1}
                                                    disabledScore={disabledScore}
                                                    setPadelScore={setPadelScore1}
                                                    prefix="padelTeam1score-"
                                                    addSet={() => {
                                                        setPadelScore1(prev => [ ...prev, "" ])
                                                        setPadelScore2(prev => [ ...prev, "" ])
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className={classNames("teamScore", {
                                        winnerTeam: !whoIsWinner && !isDrawGame && location.scoreTeam1 !== null && location.scoreTeam2 !== null,
                                    })}>
                                        <div
                                            className={classNames("teamName", {
                                                winner: !whoIsWinner && !isDrawGame && location.scoreTeam1 !== null && location.scoreTeam2 !== null,
                                            })}
                                        >
                                            <span
                                                style={longerTeamName?.team === 1 ? { width: longerTeamName.width } : undefined}
                                                ref={score2Ref}
                                            >
                                                {location.team2Name}
                                            </span>
                                        </div>
                                        <div className="inputsWrapper">
                                            { theme === "theme-soccer" && (<IMaskInput
                                                className={disabledScore ? "" : "dottedBorder"}
                                                disabled={disabledScore}
                                                mask={Number}
                                                max={99}
                                                scale={0}
                                                value={score2}
                                                onChange={
                                                    (e) => {
                                                        setScore2(e.target.value)
                                                    }
                                                }
                                                type="number"
                                                normalizeZeros={false}
                                                pattern="\\d*"
                                            />)}
                                            {theme === "theme-padel" && (
                                                <PadelScore
                                                    padelScore={padelScore2}
                                                    disabledScore={disabledScore}
                                                    setPadelScore={setPadelScore2}
                                                    prefix="padelTeam1score-"
                                                    addSet={() => {
                                                        setPadelScore1(prev => [ ...prev, "" ])
                                                        setPadelScore2(prev => [ ...prev, "" ])
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {location.scoreTeam1 === null && location.scoreTeam2 === null && isOrganizer && (
                                    <ButtonCta
                                        isAlt
                                        disabled={themeFeatures([
                                            score1 === "" || score2 === "",
                                            padelScore1.filter(score => score !== "")?.length  < 2
                                        || padelScore1.filter(score => score !== "")?.length !== padelScore2.filter(score => score !== "")?.length
                                        || padelScore1.some((value, i) => (value && !padelScore2[i]) || (!value && padelScore2[i])),
                                        ])}
                                        text="Enregistrer le score"
                                        onClick={() => sendScores(
                                            setIsLoading,
                                            location,
                                            themeFeatures([ score1, padelScore1.filter(score => score !== "") ]),
                                            themeFeatures([ score2, padelScore2.filter(score => score !== "") ]),
                                            (updatedLocation) => {
                                                setCurrentLocation({
                                                    ...location,
                                                    ...updatedLocation,
                                                })

                                            },
                                            themeHeader,
                                        )}
                                    />
                                )}
                            </div>
                        )
                    }

                    <LocationInfoBar location={location} tunnelType="BOOKING" subscription={subscription} />
                    {progressBarItems?.length > 0 && !isLoading && isOrganizer && (
                        <div className="progressWrapper">
                            <CustomProgressBar items={progressBarItems} message={"TON MATCH EST PRÊT !"} />
                        </div>
                    )}
                    { isOrganizer  && new Date() > new Date(location.end) && (
                        <>
                            <ButtonCta
                                text={"Faire la revanche"}
                                isAlt
                                onClick={() => reMatch(navigate, location, path)}
                                disabled={hasArdoises}
                            />
                            {hasArdoises && <CantBook />}
                        </>
                    )}

                    {rentalInfo &&
                        <div className="info-covid" dangerouslySetInnerHTML={{ __html: rentalInfo }}></div>
                    }

                    {!isLoading && (isOrganizer && location.cancelable && !subscription) &&
                        <NormalCancel freeCancelDate={freeCancelDate} cancellationMessage={cancellationRule?.message} cancelReservation={cancelReservation} goToCreateSuperSub={goToCreateSuperSub} location={location} />
                    }
                    {!isLoading && (isOrganizer && location.cancelable && subscription?.paymentMode === 1 && new Date() < new Date(location.start)) &&
                        <RegisterSubscriptionCancel freeCancelDate={freeCancelDate} cancelReservation={cancelReservation} goToCreateSuperSub={goToCreateSuperSub} location={location} />
                    }
                    {!isLoading && (isOrganizer && location.cancelable && subscription?.paymentMode === 2 && new Date() < new Date(location.start)) &&
                        <BillSubscriptionCancel freeCancelDate={freeCancelDate} cancelReservation={cancelReservation} goToCreateSuperSub={goToCreateSuperSub} location={location} openReport={openReport}/>
                    }

                    { (hasVideos && !isMatchNotPlayed) && (
                        <div className="sectionVideosWrapper">
                            <section className="section-videos">
                                <img className="bkg-img" src={mediaPath([ "/assets/images/people-playing.png", "/assets/images/padel/people-playing.png" ])}/>
                                <div className="section-videos__title">
                                    <img className="icon" src={mediaPath([ "/assets/icons/videos.svg","/assets/images/padel/icons/icons-camera.svg" ])}/>
                                    Retrouve tous les moments forts du match en vidéo !
                                </div>
                                <ButtonCta
                                    text="Voir les vidéos"
                                    isAlt={true}
                                    onClick={() => navigate(LocationPaths.toReservationVideos(location.id))}
                                />
                            </section>
                        </div>
                    )}

                    <CancelPopin
                        subscription={subscription}
                        freeCancelDate={freeCancelDate}
                        cancellationMessage={cancellationRule?.detailledMessage}
                        location={location}
                        isOrganizer={isOrganizer}
                        goToCreateSuperSub={goToCreateSuperSub}
                        ref={cancelPopin}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

InfoTab.propTypes = {
    initialLocation: object,
}

export default InfoTab
