import axios from "axios"

export function getLocation(locationId) {
    return axios.get(`/api/read/location/${locationId}`)
}

export function getTeams(locationId)  {
    return axios.get(`/api/read/refeering/exhibition/${locationId}/teams`)
}

export function updateTeams(locationId, params) {
    return axios.put(`/api/modify/refeering/exhibition/${locationId}/teams`, params)
}

export function storeGameSession(locationId) {
    return axios.post(`/api/create/refeering/exhibition/${locationId}/gamesession`)
}

export function getGameSession(gameSessionId) {
    return axios.get(`/api/read/refeering/exhibition/gamesession/${gameSessionId}`)
}

export function gameSessionAddGoal(gameSessionId, params) {
    return axios.put(`/api/modify/refeering/exhibition/gamesession/${gameSessionId}/goal/add`, params)
}

export function gameSessionRemoveGoal(gameSessionId, params) {
    return axios.put(`/api/modify/refeering/exhibition/gamesession/${gameSessionId}/goal/remove`, params)
}

export function updateGameSession(gameSessionId, params) {
    return axios.patch(`/api/modify/refeering/exhibition/gamesession/${gameSessionId}`, params)
}
