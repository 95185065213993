import { useEffect, useState } from "react"

export function useSwitchBefore(date) {
    const [ isBefore, setIsBefore ] = useState(date && (new Date(date) < new Date()))

    useEffect(() => {
        if (date && (new Date(date) >= new Date())) {
            setIsBefore(false)

            const timeout = setTimeout(() => setIsBefore(true), new Date(date) - new Date())

            return () => clearTimeout(timeout)
        } else {
            setIsBefore(true)
            return () => {}
        }
    }, [ date ])

    return isBefore
}
