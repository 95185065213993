import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import Modal from "../../Modal/Modal.jsx"

export const SelectTeamPopin = ModalHandler.create(({ visible, hide, teams, updateTeam }) => {
    return (
        <Modal
            isOpen={visible}
            onClose={hide}
            lockScroll
            className="renameTeamsPopin"
        >
            <header>Match complet</header>

            <section>
                <div>
                    Tu souhaites placer ce joueur ?
                </div>

                <div>
                    <button
                        className={"buttonCta isAlt"}
                        onClick={hide}
                    >
                        sur le terrain
                    </button>
                </div>

                <div className={"buttonsWrapper"}>
                    <button
                        className={"buttonCta isAlt"}
                        onClick={() => {
                            updateTeam(1)
                            hide()
                        }}
                    >
                        avec l'équipe {teams[0].label}
                    </button>

                    <button
                        className={"buttonCta isAlt"}
                        onClick={() => {
                            updateTeam(2)
                            hide()
                        }}
                    >
                        avec l'équipe {teams[1].label}
                    </button>
                </div>
            </section>
        </Modal>
    )
})
