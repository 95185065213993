export function mercureEvent(result, topic, update) {
    const hubUrl = result.headers.link.match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)[1]

    const hub = new URL(hubUrl, window.origin)

    hub.searchParams.append("topic", topic)

    const eventSource = new EventSource(hub)

    eventSource.onmessage = event => {
        update(JSON.parse(event.data))
    }

    return () => eventSource.close()
}
