import classNames from "classnames"
import dayjs from "dayjs"
import { createSearchParams } from "react-router-dom"

import { cancelLocation, registerLocationScores } from "./api"
import playersNumber from "../../../../components/reservation/LocationBlock/playersNumber.js"
import updateNextBooking from "../../../../services/updateNextBooking"

export function sendScores(setIsLoading, location, score1, score2, setLocation, themeHeader) {
    setIsLoading(true)
    registerLocationScores(
        location.id,
        score1,
        score2,
        themeHeader,
    ).then(
        (res) => {
            setIsLoading(false)
            setLocation(res?.data.data)
        },
    ).catch(() => setIsLoading(false))
}

export function generateProgressItems(location) {
    const noCompoList = [ 2, 3, 4, 5, 6, 9, 11 ]
    const expectedPLayersNumber = playersNumber?.find(item => item.types.includes(location.resourceType))?.players ?? 10

    const items = [
        {
            key: "firstProgressItem",
            label: "J'ai pris ma résa",
        },
        {
            disabled: !location?.invites || location?.invites?.length <= 1,
            key: "secondProgressItem",
            label: "J'ai invité mes joueurs",
        },
        {
            disabled: !location || location?.currentAttendees < expectedPLayersNumber,
            key: "thirdProgressItem",
            label: "Mon match est complet",
        },
        {
            disabled: !location || location?.composition?.filter(player => player.teamId && player.teamSlot !== undefined)?.length !== location.maxAttendees,
            hide: noCompoList.includes(location?.resourceType),
            key: "fourthProgressItem",
            label: "J'ai fait ma compo",
        },
        {
            disabled: !location || location?.amountDue > 0,
            key: "fifthProgressItem",
            label: "Ma résa est réglée",
        },
    ]
    items.sort((a, b) => {
        if (a.disabled && !b.disabled) {
            return 1
        } else if (!a.disabled && b.disabled) {
            return -1
        } else {
            return 0
        }
    })

    return items.filter(item => !item.hide)
}

export function reMatch(navigate, location, path) {
    function daysUntilNextWeekDay(actualDay, nextWantedDay) {
        if (actualDay === nextWantedDay) {
            return 7
        } else if (actualDay < nextWantedDay) {
            return nextWantedDay - actualDay
        } else {
            return 7 - actualDay + nextWantedDay
        }
    }
    const todayDay = new Date().getDay()
    const locationStartDate = new Date(location.start)
    const locationDayOfTheWeek = locationStartDate.getDay()

    const nextSameDayOfWeekDate = dayjs(
        new Date(),
    ).add(
        daysUntilNextWeekDay(todayDay, locationDayOfTheWeek),
        "day",
    ).toDate()

    const locationMinutes = locationStartDate.getMinutes() + (locationStartDate.getMinutes() - 10 < 0 ? "0" : "")
    const locationDuration = parseInt(location.duration.split(" ")[0] * 60) +
        (location.duration.split(" ")[2] ? parseInt(location.duration.split(" ")[2]) : 0)
    
    const params = {
        centerId: location.centerId,
        date: dayjs(nextSameDayOfWeekDate).format("YYYY-MM-DD"),
        duration: locationDuration,
        hour: locationStartDate.getHours(),
        mn: locationMinutes,
        resourceType: location.resourceType,
    }
    navigate({
        pathname: path("/reserver"),
        search: createSearchParams(params).toString(),
    })
}

export function confirmCancel(freeCancelDate, subscription, navigate, location, path) {
    const wording = subscription ? "session" : "réservation"
    const freeCancelMessage = `Ta ${wording} a bien été annulée${subscription ? "." : ", ton paiement ne sera pas débité."}`
    const paymentModeMessage = subscription?.paymentMode === 1 ?
        "Attention, après 2 annulations hors délai, ton abonnement est susceptible d'être résilié."
        :
        "L'annulation étant hors délai, ta session est perdue."
    
    const subscriptionMessage = subscription ?
        paymentModeMessage
        :
        "L'annulation étant à moins de 48h du début du match, tu ne seras pas remboursé du montant payé."
    
    let message = freeCancelDate ?
        freeCancelMessage
        :
        `Ta ${wording} a bien été annulée. ${subscriptionMessage}`

    return new Promise((resolve, reject) => {
        cancelLocation(location.id).then(() => {
            updateNextBooking()
            navigate(
                path("/confirmcommand"), {
                    state: {
                        customContentIdentifier: "locations",
                        customLink: "/reservations/locations",
                        fromGestion: true,
                        message: !subscription ? "Ta réservation a bien été annulée." : message,
                        success: true,
                        title: `${subscription ? "Session" : "Réservation"} annulée`,
                        tunnelType: "BOOKING",
                    },
                },
            )
            resolve() // Resolve when the operation is successful
        }).catch(error => {
            reject(error) // Reject in case of an error
        })
    })
}

export const matchResultDisplay = (playerComposition, userLoaded, location, theme) => {
    const currentUserTeam = () => {
        const currentUserComposition = playerComposition?.composition?.find(player => player.playerId === userLoaded.contactId)
        if (currentUserComposition) {
            if (currentUserComposition.teamId === 1) {
                return "team1"
            } else if (currentUserComposition.teamId === 2) {
                return "team2"
            }
        }
    }
    const isTie = location.scoreTeam1 === location.scoreTeam2
    const isVictory = (currentUserTeam() === "team1" && location.scoreTeam1 > location.scoreTeam2
        || (currentUserTeam() === "team2" && location.scoreTeam1 < location.scoreTeam2)
    )
    const isDefeat =  (currentUserTeam() === "team2" && location.scoreTeam1 > location.scoreTeam2
        || (currentUserTeam() === "team1" && location.scoreTeam1 < location.scoreTeam2)
    )

    return (
        <>
            {theme === "theme-soccer" && (
                <img
                    className="matchResult"
                    src={
                        isTie ?
                            "/assets/images/tie.png"
                            :
                            isVictory ?
                                "/assets/images/victory.png"
                                :
                                isDefeat ?
                                    "/assets/images/defeat.png"
                                    :
                                    ""
                    }
                />
            )}
            {theme === "theme-padel" && currentUserTeam() && (
                <div className={classNames("padelUserResult", {
                    defeat: isDefeat,
                    tie: isTie,
                    victory: isVictory,
                })}>
                    <span>
                        {
                            isTie ?
                                "MATCH NUL"
                                :
                                isVictory ?
                                    "VICTOIRE"
                                    :
                                    isDefeat ?
                                        "DÉFAITE"
                                        :
                                        ""
                        }
                    </span>
                </div>
            )}
        </>
    )
}

export const getCurrentReservationCancellationRule = (location) => {
    const locationStart = dayjs(location.start)
    const now = dayjs()
    const minutesDiff = locationStart.diff(now, "minutes")
    const hourDiff = minutesDiff / 60

    if (hourDiff > 48) {
        return location?.reservationCancellationRule?.ruleOnTime
    } else if (hourDiff <= 48 && hourDiff > 24) {
        return location?.reservationCancellationRule?.ruleH48
    } else if (hourDiff <= 24 && hourDiff > 0) {
        return location?.reservationCancellationRule?.ruleH24
    } else {
        return location?.reservationCancellationRule?.rulePvpp
    }
}
