import { array, bool, func, number, object, oneOfType } from "prop-types"
import { useEffect, useState } from "react"

import getBase64ImageFromUrl from "../../../services/getBase64ImageFromUrl"
import getBaseUrl from "../../../services/getBaseUrl"

import "./Player.scss"

function Player({ player, onClick, closeOnClick, fieldPlace, selected, onBench, goalCount, isMVP }) {

    const [ playerImage, setPlayerImage ] = useState(player?.pictureUrl)

    useEffect(
        () => {
            if (player?.pictureUrl) {
                if (getBaseUrl() === "http://localhost:3000") {
                    setPlayerImage(player.pictureUrl)
                } else {
                    getBase64ImageFromUrl(
                        player.pictureUrl,
                    ).then(
                        (res) => setPlayerImage(res),
                    )
                }
            } else {
                setPlayerImage(undefined)
            }
        }, [ player ],
    )

    return (
        <div
            className={
                selected ?
                    "selected player"
                    :
                    "player"
            }
            data-testid="handleSelection"
            onClick={
                onClick ?
                    () => onClick(player, fieldPlace)
                    :
                    undefined
            }
        >
            {player ? (
                <>
                    {player.teamId && player.teamSlot === null && (
                        <div className={"player-selected-team"}>
                            {player.reservation[`team${player.teamId}Name`]}

                            <img
                                className="close-cross-b"
                                src="/assets/icons/cross-orange-white.svg"
                                data-testid="remove"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    closeOnClick()
                                }}
                            />
                        </div>
                    )}
                    <div className={"avatar-frame " + (selected ? "selected-image" : "image-container")}>
                        <img
                            id={playerImage ? playerImage : null}
                            className={onBench ? "image with-player bench" : "image with-player"}
                            src={
                                playerImage ?
                                    playerImage
                                    :
                                    "/assets/images/user-placeholder-b.svg"
                            }
                        />

                        { (selected && !onBench) && (
                            <img
                                className="close-cross"
                                src="/assets/images/Close-W-B.svg"
                                data-testid="remove"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    closeOnClick()
                                }}
                            />      
                        )}

                        {!!(goalCount && goalCount !== 0 && goalCount !== "0") && ( 
                            <div className="goalCount">
                                <div className="goalCount__wrapper">
                                    <img src="/assets/images/football-ball.svg"/>
                                    <div className="goalCount__number">
                                        {goalCount}
                                    </div>
                                </div>
                            </div>
                        )}

                        {isMVP && (
                            <img className="mvp-star" src="/assets/icons/star--orange.svg"/>
                        )}
                    </div>
                    <p>{player.firstname + " " + player.lastname?.charAt(0) + "."}</p>             
                </>
            ) : (
                <>
                    <div className={selected ? "selected empty" : "empty"} />
                    <p></p>
                </>
            )}
        </div>
    )
}

Player.propTypes = {
    closeOnClick: func,
    fieldPlace: array,
    goalCount: oneOfType([ number, bool ]),
    isMVP: bool,
    onBench: bool,
    onClick: func,
    player: object,
    selected: bool,
}

export default Player
