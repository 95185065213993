/* eslint-disable sort-keys-fix/sort-keys-fix */

import dayjs from "dayjs"

import { BestScorers } from "./components/visuals/BestScorers/BestScorers.jsx"
import { NextGame } from "./components/visuals/NextGame/NextGame.jsx"
import { PassedGame } from "./components/visuals/PassedGame/PassedGame.jsx"
import { Player } from "./components/visuals/Player/Player.jsx"
import { TeamAgenda } from "./components/visuals/TeamAgenda/TeamAgenda.jsx"
import { TeamPlayers } from "./components/visuals/TeamPlayers/TeamPlayers.jsx"
import { TeamRoster } from "./components/visuals/TeamRoster/TeamRoster.jsx"
import { TeamsRanking } from "./components/visuals/TeamsRanking/TeamsRanking.jsx"

export const communicationPackageFilters = {
    team: {
        label: "Visuels d'équipe",
        value: "team",
    },
    player: {
        label: "Visuels individuels",
        value: "player",
    },
}

export const communicationPackageDynamicContent = {
    game: {
        label: "Sélectionne ton match",
        placeholder: "Match",
        value: "game",
    },
}

export const communicationPackageConfig = [
    {
        category: communicationPackageFilters.team.value,
        component: BestScorers,
        description: "Meilleurs buteur de l’équipe : Qui a le plus scoré ? vous le saurez dans ce visuel présentant" +
            " les 5 meilleurs buteurs de votre équipe !",
        fileName: "meilleurs-buteurs",
        multiPage: {
            getter: (data) => data.leagueTeam.players,
            limit: 5,
        },
        selectTeamPlayer: true,
        slug: "bestScorers",
        title: "Meilleurs buteurs de l’équipe",
    },
    {
        category: communicationPackageFilters.team.value,
        component: TeamsRanking,
        description: "Classement équipe : vous trouverez dans ce visuel le classement de l'ensemble des équipes de votre ligue et/ou division. Votre équipe apparaît surlignée en jaune afin de mettre en valeur votre place dans le classement.",
        fileName: "classement-ligue",
        multiPage: {
            getter: (data) => data?.league?.teams,
            limit: 9,
        },
        slug: "leagueRanking",
        title: "Classement de la ligue",
    },
    {
        category: communicationPackageFilters.team.value,
        component: TeamPlayers,
        description: "Présentation de l’effectif : Tous les joueurs de votre effectif sont présentés dans ce visuel. N’hésitez pas à ajouter vos postes au sein de votre entreprise lors de votre inscription pour obtenir un véritable organigramme !",
        fileName: "presentation-effectif",
        multiPage: {
            getter: (data) => data?.leagueTeam?.players,
            limit: 16,
        },
        slug: "teamPlayers",
        title :"Présentation de l’effectif",
    },
    {
        category: communicationPackageFilters.team.value,
        component: TeamRoster,
        description: "Photo d’équipe : Votre équipe, votre entreprise, votre crew !",
        fileName: "photo-equipe",
        slug: "teamRoster",
        title: "Photo de l’équipe",
    },
    {
        category: communicationPackageFilters.team.value,
        component: NextGame,
        content: "game",
        contentFilter: (content) => content?.filter((game) => dayjs().isBefore(dayjs(game?.data?.date))),
        description: "Prochain match planifié : Ne manquez plus aucune échéance de Business League ! Le visuel de présentation du prochain match contient tous les détails de votre prochaine rencontre. Heure, adresse du lieu du match, adversaires : à vos crampons !",
        fileName: "prochain-match",
        selectTeamPlayer: true,
        slug: "nextGame",
        title: "Prochain match planifié",
    },
    {
        category: communicationPackageFilters.team.value,
        component: PassedGame,
        content: "game",
        contentFilter: (content) => content?.filter((game) => dayjs().isAfter(dayjs(game?.data?.date))),
        fileName: "dernier-resultat",
        selectTeamPlayer: true,
        slug: "passedGame",
        title: "Dernier résultat",
    },
    {
        category: communicationPackageFilters.team.value,
        component: TeamAgenda,
        description: "Ce calendrier permet de faire la promotion de tous les matchs de l’équipe pendant le mois." +
            " Pour l’équipe c’est un mémo, et pour les spectateurs potentiels une guideline pour suivre ses joueurs favoris.",
        fileName: "calendrier-mensuel",
        multiPage: {
            getter: (data) => data?.teamGames,
            limit: 4,
        },
        slug: "monthlyCalendar",
        title: "Calendrier mensuel des matchs",
    },
    {
        category: communicationPackageFilters.player.value,
        component: Player,
        description: "Vous trouverez dans ce visuel un résumé de vos performances lors des derniers matchs. Nombre" +
            " de buts, de matchs joués, de cartons jaunes et rouges, de votes homme du match, etc.",
        fileName: "resume-joueur",
        slug: "player",
        title: "Mon résumé joueur",
    },
]

