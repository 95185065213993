import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Outlet, useLocation, useMatch, useNavigate, useSearchParams } from "react-router-dom"

import { BusinessLeagueProfile } from "./components/BusinessLeagueProfile/BusinessLeagueProfile.jsx"
import FirstConnection from "./components/firstConnection/FirstConnection.jsx"
import Footer from "./components/layout/footer/footer"
import { TeamInvitModal } from "./components/league/TeamInvitModal/TeamInvitModal.jsx"
import Preloader from "./components/loaders/preloader/preloader"
import MenuBuilder from "./components/menu/menuBuilder"
import OverlayMenu from "./components/menu/overlayMenu/overlay_menu"
import OverlayProfileMenu from "./components/menu/overlayProfileMenu/overlayProfileMenu"
import MessagePopin from "./components/popin/MessagePopin/MessagePopin.jsx"
import { useTheme, useThemeInitialized, useThemeUpdate } from "./components/ThemeContext/ThemeContext.jsx"
import Header from "./entries/main/componentsContainers/header"
import OverlayConfirmation from "./entries/main/componentsContainers/overlay_confirmation"
import OverlayLevel3 from "./entries/main/componentsContainers/overlay_level3"
import useRedirections from "./hooks/routing/redirection/useRedirections"
import useAuth from "./hooks/useAuth"
import useCookies from "./hooks/useCookies"
import useIsMobile from "./hooks/useIsMobile"
import useThemeFeatures from "./hooks/useThemeFeatures"
import { acceptTeamInvitation } from "./pages/league/api/leagueTeam.js"
import { getLeagueTeamById } from "./pages/league/api/myLeague.js"
import UpdateApp from "./pages/UpdateApp/UpdateApp"
import ModalHandler from "./providers/Modal/ModalHandler.jsx"
import { LeaguePath } from "./routes/ApplicationPaths/LeaguePaths.js"
import { LocationPaths } from "./routes/ApplicationPaths/locationPaths.js"
import RouterManager from "./routes/RoutesMapping.jsx"
import usePath from "./routes/services/usePath"
import { sendNotifTokenToOzmo } from "./services/handleAppToken"
import { loadUrlQueryParameters } from "./store/actions/scaffold/index.js"

function App() {
    const [ customRedirection, setCustomRedirection ] = useState(null)
    const [ firstConnectionCompleted, setFirstConnectionCompleted ] = useState(false)

    const [ queryParameters ] = useSearchParams()
    const setTheme = useThemeUpdate()

    const isMobile = useIsMobile()
    const auth = useAuth()
    const location = useLocation()
    const { isWebview, isAppV2 } = useCookies()
    const [ themeInitialized ] = useThemeInitialized()

    const userLoaded = useSelector(state => state.userLoaded)
    const dispatch = useDispatch()

    const path = usePath()
    const themeFeatures = useThemeFeatures()
    const theme = useTheme()
    const [ searchParams ] = useSearchParams()
    const navigate = useNavigate()

    const { isLoading } = useRedirections()

    const matchArbitrage = useMatch(LocationPaths.arbitrage + "/*")

    const openFirstConnectionPopin = () => {
        ModalHandler.show(FirstConnection, {
            currentUser: userLoaded,
        }).then(() => {
            setTheme(theme)
            setFirstConnectionCompleted(true)
            clearAllBodyScrollLocks()
        })
        disableBodyScroll(document.getElementsByClassName(".skylight-first-connection .modal-content"), { allowTouchMove: () => true })
    }

    // eslint-disable-next-line no-unused-vars
    const openBusinessLeaguePopin = () => {
        ModalHandler.show(BusinessLeagueProfile, {
            currentUser: userLoaded,
        })
    }

    useEffect(() => {
        document.body.classList.remove("orientation-portrait")
        document.body.classList.remove("orientation-landscape")
        document.body.classList.add("orientation-" + (isMobile ? "portrait" : "landscape"))
    }, [ isMobile ])

    const params = []

    for (let entry of searchParams.entries()) {
        params.push(entry)
    }

    useEffect(
        () => {
            // handling onBoarding modals and logic ...
            if (userLoaded?.id && isWebview) {sendNotifTokenToOzmo(userLoaded.id)}

            const shouldOpenFirstConnection = theme !== "theme-business" && userLoaded?.firstConnection && !firstConnectionCompleted

            if (shouldOpenFirstConnection) {
                openFirstConnectionPopin()
            }

            if (!userLoaded?.businessLeagueProfile && !shouldOpenFirstConnection && userLoaded?.id && theme === "theme-business") {
                openBusinessLeaguePopin()
            }

        }, [ userLoaded, isWebview ],
    )

    useEffect(() => {
        const urlTheme = searchParams?.get("theme")
        if (urlTheme && !themeInitialized) {
            setTheme(urlTheme)
        }
    }, [ theme ])

    const showLeagueTeamConfirmeModal = (title, message) => {
        localStorage.removeItem("publicJoinToken")
        ModalHandler.show(MessagePopin, {
            message: message,
            title: title,
        }).then(clearAllBodyScrollLocks)
    }

    useEffect(() => {
        if (queryParameters.invitation === "TYPE_LEAGUE_TEAM" || searchParams?.get("invitation") === "TYPE_LEAGUE_TEAM" && Object.keys(userLoaded).length > 0) {
            const teamId = queryParameters.id ?? searchParams?.get("id")
            const fromUser = queryParameters.fromUser ?? searchParams?.get("fromUser")
            const publicJoinToken =  JSON.parse(localStorage.getItem("publicJoinToken"))
            if (publicJoinToken?.token) {
                acceptTeamInvitation(publicJoinToken?.token).then(() => {
                    navigate(LeaguePath.toLeagueInfosHome(publicJoinToken.id))
                    showLeagueTeamConfirmeModal("Confirmation", "Tu as rejoint l'équipe !")
                })
                return
            }

            if (teamId) {
                getLeagueTeamById(teamId).then((team) => {
                    if (team?.players?.find(player => player.id === userLoaded.contactId)) {
                        navigate(LeaguePath.toLeagueInfosHome(team?.id))
                        showLeagueTeamConfirmeModal("Attention", `Tu fais déjà partie de l'équipe ${team?.name} !`)
                        return
                    }
                    if (team?.publicJoinToken) {
                        ModalHandler.show(TeamInvitModal, {
                            acceptCallback: () => {
                                acceptTeamInvitation(team?.publicJoinToken).then(() => {
                                    navigate(LeaguePath.toLeagueInfosHome(team?.id))
                                    showLeagueTeamConfirmeModal("Confirmation", `Tu as rejoint l'équipe ${team?.name} !`)
                                }).finally(() => {
                                    ModalHandler.hide(TeamInvitModal)
                                })
                            },
                            fromUser: fromUser,
                            loggedIn: true,
                            team: team,

                        }).then(() => {
                            clearAllBodyScrollLocks()
                            dispatch(loadUrlQueryParameters(null))
                            navigate("/")
                        })
                    }
                })
            }
        }
    }, [ userLoaded ])

    useEffect(
        () => {
            const action = queryParameters?.get("action")
            if (action) {
                const urlTheme = queryParameters?.get("theme")
                const urlPrefixe = urlTheme && urlTheme === "theme-padel" ? "/padel" : ""
                if (action === "leagueSubscription")  {return setCustomRedirection(urlPrefixe + "/urbanleague/new")}
                if (action === "kidsStageSubscription")  {return setCustomRedirection(themeFeatures([ "/kids/stagesDeFoot/new", "/padel/coursStages/inscription" ]))}
                if (action === "kidsSchoolSubscription") {setCustomRedirection(urlPrefixe + `/?action=kidsSchoolSubscription&center=${queryParameters?.get("center")}&type=${queryParameters?.get("type")}`)}
            } else {setCustomRedirection(null)}
        }, [ queryParameters ],
    )

    if (!isAppV2 && isWebview) {
        return <UpdateApp isMaintenanceMode={import.meta.env.VITE_WEBVIEW_MAINTENANCE_MODE === "true"}/>
    }

    return (
        <RouterManager>
            {(auth === null || isLoading) && <Preloader fixed cls={"opaque fullPage"} />}
            {!isAppV2 && (<div className="mobileMenu show-st">
                {auth && !matchArbitrage && <Header/>}
            </div>)}

            <div className="desktopMenu hide-st">
                {auth && !matchArbitrage && <MenuBuilder />}
            </div>

            <main id="content" className={classNames("content", {
                "arbitrage": !!matchArbitrage,
                "isAppV2": isAppV2,
                "webview": isWebview || isAppV2,
            })}>
                {auth !== null  && (
                    auth ?
                        (
                            themeInitialized ?
                                <Outlet/>
                                : <Preloader fixed/>
                        )
                        : (
                            <Navigate
                                to={
                                    searchParams?.get("theme") === "theme-padel" ?
                                        "/padel/login"
                                        :
                                        path("/login")
                                }
                                state={{
                                    afterLoginUrl:  customRedirection ?? location.pathname + window.location.search,
                                }}
                            />
                        )
                )}
            </main>

            <OverlayLevel3/>
            <OverlayConfirmation/>
            {auth && !isAppV2 && (
                <>
                    <OverlayMenu />
                    <OverlayProfileMenu/>

                    <Footer customFooterClass={classNames({
                        hide: isWebview && isAppV2 || matchArbitrage,
                    })}/>
                </>
            )}
        </RouterManager>
    )
}

export default App
