import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"

import ConfirmPopin from "../../../../../components/ConfirmPopin/ConfirmPopin.jsx"
import Preloader from "../../../../../components/loaders/preloader/preloader.jsx"
import ModalHandler from "../../../../../providers/Modal/ModalHandler.jsx"
import { LocationPaths } from "../../../../../routes/ApplicationPaths/locationPaths.js"
import { mercureEvent } from "../../../../../services/mercure.js"
import { usePreventLockScreen } from "../../../../../services/preventLockScreens.js"
import { useLocationStore } from "../../../store/store.js"
import { getGameSession, getLocation, storeGameSession } from "../api.js"
import { ArbitrageContext } from "../ArbitrageContext.js"

import "./Arbitrage.scss"

export function ArbitrageLayout() {
    const params = useParams()

    const { currentLocation, setCurrentLocation } = useLocationStore()
    const [ gameSession, setGameSession ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ saving, setSaving ] = useState(false)
    const navigate = useNavigate()
    
    usePreventLockScreen()

    const checkTimeSession = async location => {
        if (location) {
            if (dayjs(location.start).diff(new Date, "minutes") >= 15) {
                await ModalHandler.show(ConfirmPopin, {
                    buttonsLabel: { confirm: "OK" },
                    description: "La session d'arbitrage n'est pas encore disponible.",
                    isAlert: true,
                    title: "Session d'arbitrage indisponible",
                })

                return false
            }

            if (new Date(location.end) < new Date()) {
                await ModalHandler.show(ConfirmPopin, {
                    buttonsLabel: { confirm: "OK" },
                    description: "La session d'arbitrage est terminée.",
                    isAlert: true,
                    title: "Session d'arbitrage terminée.",
                })

                return false
            }
        }

        return true
    }

    const load = async () => {
        if (currentLocation?.id !== params.locationId) {
            const result = await getLocation(params.locationId)

            if (!(await checkTimeSession(result.data.data))) {
                throw new Error("Session indisponible")
            }

            setCurrentLocation(result.data.data)
        }

        const result = await storeGameSession(params.locationId)

        setGameSession(result.data.data)

        setIsLoading(false)

        return mercureEvent(result, `gamesession/${result.data.data.GameSessionId}`, event => {
            if (event.action === "updated") {
                setSaving(true)

                getGameSession(result.data.data.GameSessionId)
                    .then(result => setGameSession(result.data.data))
                    .finally(() => setTimeout(() => setSaving(false), 3000))
            }

            if (event.action === "stopped") {
                ModalHandler.show(ConfirmPopin, {
                    buttonsLabel: { confirm: "OK" },
                    description: "La session d'arbitrage s'est arrêtée.",
                    isAlert: true,
                    title: "Fin de l'arbitrage",
                }).then(() => navigate(LocationPaths.toReservationInfos(params.locationId)))
            }
        })
    }
    
    useEffect(() => {
        let clear

        load()
            .then(clearEvent => clear = clearEvent)
            .catch(() => {
                navigate(LocationPaths.toReservationInfos(params.locationId))
            })

        return () => clear ? clear() : null
    }, [ params.locationId ])

    useEffect(() => {
        if (currentLocation) {
            const timeout = setTimeout(
                () => checkTimeSession(currentLocation)
                    .catch(() => navigate(LocationPaths.toReservationInfos(params.locationId))),
                dayjs(currentLocation.end).add(15,"minutes").diff(),
            )

            return () => clearTimeout(timeout)
        }
    }, [ currentLocation ])

    const gameSessionComputed = useMemo(() => {
        if (!currentLocation || !gameSession) {
            return null
        }

        const dict = currentLocation.invites?.reduce((carry, invite) => ({ ...carry, [invite.id]: invite }), {}) ?? {}

        const mapPlayers = players => players.map(player => ({ ...player, detail: dict[player.InvitationId] ?? null }))

        return {
            ...gameSession,
            PlayersTeam1: mapPlayers(gameSession.PlayersTeam1 ?? []),
            PlayersTeam2: mapPlayers(gameSession.PlayersTeam2 ?? []),
        }
    }, [ currentLocation, gameSession ])
    
    if (isLoading) {
        return <Preloader fixed />
    }
    
    return (
        <div className={"arbitrage-layout"}>
            <ArbitrageContext.Provider value={{ gameSession: gameSessionComputed, saving, setGameSession, setSaving }}>
                <Outlet />
            </ArbitrageContext.Provider>
        </div>
    )
}
