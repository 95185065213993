import { useContext } from "react"
import { Link, useNavigate } from "react-router-dom"

import { ArbitrageContext } from "../ArbitrageContext.js"

export default function ArbitrageRecap() {
    const { gameSession } = useContext(ArbitrageContext)

    const navigate = useNavigate()

    const winner = gameSession.ScoreTeam1 === gameSession.ScoreTeam2
        ? 0
        : gameSession.ScoreTeam1 > gameSession.ScoreTeam2
            ? 1
            : 2

    return (
        <div className={"arbitrage-recap"}>
            <div className={"arbitrage-recap-teams"}>
                {Array.from({ length: 2 }).map((_, i) => ++i && (
                    <div key={i} className={"arbitrage-recap-team"}>
                        <div className={"arbitrage-recap-team-title"}>
                            <div>
                                {gameSession[`Team${i}Name`]}
                            </div>

                            <div className={winner === i ? "arbitrage-recap-team-winner" : "arbitrage-recap-team-loser"}>
                                {gameSession[`ScoreTeam${i}`]}
                            </div>
                        </div>

                        <div className={"arbitrage-recap-team-players"}>
                            {[ ...gameSession[`PlayersTeam${i}`] ].sort((a, b) => b.NbGoals - a.NbGoals).map(player => (
                                <div
                                    key={player.ContactId}
                                    className={"arbitrage-recap-team-player"}
                                >
                                    <div className={"arbitrage-recap-team-player-name"}>
                                        <img
                                            src={player.detail?.pictureUrl ?? "/assets/images/user-placeholder-b.svg"}
                                            alt="profile"
                                            className={"arbitrage-recap-team-player-img"}
                                        />

                                        {player.Name}
                                    </div>

                                    <div className={"arbitrage-recap-team-goals"}>
                                        <img
                                            alt={"score"}
                                            src="/assets/icons/score-icon.svg"
                                            className={"arbitrage-recap-team-goals-icon"}
                                        />

                                        <div className={`arbitrage-recap-team-goals-value ${
                                            player.NbGoals > 9 ? "two-digits" : ""
                                        }`}>
                                            {player.NbGoals}
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className={"arbitrage-recap-team-player"}>
                                <div className={"arbitrage-recap-team-player-name"}>
                                    <img
                                        src={"/assets/images/user-placeholder-b.svg"}
                                        alt="profile"
                                        className={"arbitrage-recap-team-player-img"}
                                    />

                                    Invité
                                </div>

                                <div className={"arbitrage-recap-team-goals"}>
                                    <img
                                        alt={"score"}
                                        src="/assets/icons/score-icon.svg"
                                        className={"arbitrage-recap-team-goals-icon"}
                                    />

                                    <div className={`arbitrage-recap-team-goals-value ${
                                        gameSession[`GuestGoalsTeam${i}`] > 9 ? "two-digits" : ""
                                    }`}>
                                        {gameSession[`GuestGoalsTeam${i}`]}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className={"arbitrage-recap-actions"}>
                <Link
                    to={".."}
                    className={"arbitrage-recap-back"}
                >
                    RETOUR
                </Link>

                <button
                    onClick={() => navigate("../quitter")}
                    className={"arbitrage-recap-exit"}
                >
                    QUITTER LE MATCH
                </button>
            </div>
        </div>
    )
}
