import { useContext } from "react"
import { Link } from "react-router-dom"

import { Clock } from "./Clock.jsx"
import { gameSessionRemoveGoal } from "../api.js"
import { ArbitrageContext } from "../ArbitrageContext.js"

export function Arbitrage() {
    const { gameSession, setGameSession, saving, setSaving } = useContext(ArbitrageContext)

    return (
        <div className="arbitrage-main">
            <div className={"arbitrage-clock"}>
                <Clock/>

                <Link
                    to={"recapitulatif"}
                    className={"arbitrage-stop"}
                />
            </div>

            <div className={"arbitrage-main-teams"}>
                {Array.from({ length: 2 }).map((_, i) => ++i && (
                    <div key={i} className={"arbitrage-main-team"}>
                        <div className={"arbitrage-score-title"}>
                            {gameSession[`Team${i}Name`]}
                        </div>

                        <div className={"arbitrage-score"}>
                            <button
                                onClick={
                                    () => {
                                        setSaving(true)

                                        gameSessionRemoveGoal(gameSession.GameSessionId, { ContactId: null, Team: i })
                                            .then(result => setGameSession(result.data.data))
                                            .catch()
                                            .finally(() => setTimeout(() => setSaving(false), 3000))
                                    }
                                }
                                className={"arbitrage-score-moins"}
                                disabled={!gameSession[`ScoreTeam${i}`] || saving}
                            >
                                <svg width="14" height="4" stroke="currentColor">
                                    <path d="M14 2L1.78814e-07 2" strokeWidth="3"/>
                                </svg>
                            </button>

                            <div className={"arbitrage-score-value"}>
                                {gameSession[`ScoreTeam${i}`]}
                            </div>

                            <Link
                                to={`./goal/${i}`}
                                className={`arbitrage-score-plus ${saving ? "arbitrage-score-plus-disabled" : ""}`}
                            >
                                <svg width="14" height="14" fill="none" stroke="currentColor">
                                    <path d="M7 0V14" strokeWidth="3"/>
                                    <path d="M14 7L1.78814e-07 7" strokeWidth="3"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
